@import url('https://fonts.googleapis.com/css?family=Orbitron');

body {
  margin: 0;
  font-family: 'Orbitron';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1d1d1d;
  color: #5c5b5b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scroll-down {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skills {
  font-size: 15px;
  color: white;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
}

.skills p{
  border: 1px solid #5c5b5b;
  background-color: #5c5b5b;
  border-radius: 5px;
  margin: 10px;
  padding: 5px;
}

.skills {
  width: 100%;
}

.success-btn-Link {
  text-decoration: none;
  color: #05fdd8;
}

.btn:hover {
  background-color: #05fdd8;
  color: #1d1d1d;
}

.contactForm-submit:hover {
  color: #1d1d1d;
  background-color: #05fdd8;
}

.btn p:hover {
  color: #1d1d1d;
}

.success-btn:hover {
  color: #1d1d1d;
  background-color: #05fdd8;
}

.success-btn p:hover {
  color: #1d1d1d;
}

.btn input:hover {
  color: #1d1d1d;
}

.contact-form {
  display: flex;
  justify-content: center;
}

.submit:hover {
  background-color: #05fdd8;
  color: #1d1d1d;
}

input {
  background-color: #1d1d1d;
  font-family: "Orbitron";
  color: #05fdd8;
}

textarea {
  background-color: #1d1d1d;
  font-family: "Orbitron";
  color: #05fdd8;
}

::placeholder {
  color: #5c5b5b;
  font-family: "Orbitron";
}

.button-p {
  width: 250px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 10%;
  margin-right: 34.8%;
}

.link-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1100px;
}

.p20 {
  margin-left: 20px;
}

.p40 {
  margin-left: 40px;
}

.p40-2 {
  margin-left: 40px;
  margin-top: 10%;
}

.mainjs-div {
  margin: 25px;
  font-size: small;
}

.links-p {
  margin-right: 15px
}

.links-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkedin-github-img {
  width: 60px;
}

.email-img {
  width: 40px;
  margin-left: 20px;
}

.resume-img {
  width: 40px;
  margin-left: 10px;
}

.header-div {
  margin-left: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  width: 1100px;
}

.header-h1 {
  font-size: 50px;
  color: #05fdd8;
}

.header-p {
  font-size: 25px;
  color: #8d8d8d;
  margin-top: 45px;
}

.about-div {
  margin: -1% 0px 0px 4.5%;
  font-size: 40px;
  display: flex;
  flex-direction: column;
  width: 1100px;
}

.about-symbols {
  font-size: small;
}

.about-h1-1 {
  margin: -1% 0px 0px 0px;
  color: white;
}

.about-h1-2 {
  margin: -1.5% 0px 0px 0px;
  color: white;
}

.about-h1-3 {
  margin: -1.5% 0px -1.25% 0px;
  color: white;
}

.about-span {
  color: #05fdd8;
}

.about-symbols-1 {
  margin-bottom: -5px;
  font-size: small;
}

.about-symbols-2 {
  margin: -.5% 0px -.5% 0px;
  color: #8d8d8d;
}

.about-link {
  text-decoration: none;
  color: #05fdd8;
}

.aboutMe-div-1 {
  margin-left: 4%;
  width: 1100px;
}

.aboutMe-div-2 {
  font-size: 40px;
}

.aboutMe-symbols {
  font-size: small;
}

.aboutMe-h2 {
  margin: -1% 0px 0px 0px;
  color: #05fdd8;
}

.aboutMe-symbols-1 {
  font-size: small;
  margin-bottom: 60px;
}

.aboutMe-div-3 {
  font-size: 40px;
  width: 100%;
  padding-right: 5%;
}

.aboutMe-p {
  color: white;
  font-size: 20px;
}

.aboutMe-a {
  text-decoration: none;
  color: #05fdd8;
}

.project-main {
  margin-left: 4.5%;
  width: 1100px;
}

.project-main-2 {
  font-size: 40px;
}

.project-symbols {
  font-size: small;
}

.projects-h2 {
  margin: -1% 0px 0px 0px;
  color: #05fdd8;
}

.projects-div {
  margin-left: 2%;
  width: 100%;
}

.projects-inner-div {
  display: flex;
  justify-content: space-between;
  margin-left: 2%;
}

.projects-text-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projects-text-p {
  color: white;
  font-size: small;
}

.projects-github-img {
  width: 60px;
}

.projects-pong-img {
  width: 400px;
  margin-top: 40px;
}

.projects-memory-img {
  width: 400px;
  height: 275px;
  margin-top: 40px;
}

.contactForm-div {
  width: 1100px;
  margin-left: 25%;
  margin-top: 50px;
}

.contactForm-input-name {
  width: 50%;
  margin-left: 50px;
  border: 1px solid #05fdd8;
}

.contactForm-input-email {
  margin-left: 55px;
  margin-top: 50px;
  width: 50%;
  border: 1px solid #05fdd8;
}

.contactForm-input-subject {
  margin-left: 35px;
  margin-top: 50px;
  width: 50%;
  border: 1px solid #05fdd8;
}

.contactForm-input-message {
  margin-left: 25px;
  margin-top: 50px;
  width: 50%;
  height: 100px;
  border: 1px solid #05fdd8;
}

.contactForm-submit {
  width: 250px;
  height: 60px;
  display: "flex";
  justify-content: "center";
  align-items: "center";
  background-color: #1d1d1d;
  font-family: "Orbitron";
  color: #05fdd8;
  font-size: x-large;
  border: none;
}

.btn {
  border: 1px solid #05fdd8;
  width: 30%;
  font-size: x-large;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  color: #05fdd8;
  margin-left: 9%;
}

.btn-size {
  width: 25%;
}

.contactForm-Link-p {
  width: 250px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactForm-Link {
  text-decoration: none;
  color: #05fdd8;
}

.contactForm-Links-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  margin-right: 33%;
  margin-left: 5%;
}

.contactForm-symbols-1 {
  margin-right: 15px;
  font-size: small;
}

.contactForm-symbols-2 {
  margin-left: 20px;
  font-size: small;
}

.contactMe-symbols-1 {
  font-size: small;
}

.contactMe-symbols-2 {
  margin-left: 20px;
  font-size: small;
}

.contactMe-symbols-3 {
  margin-left: 40px;
  font-size: small;
}

.contactMe-h2 {
  color: #05fdd8;
  font-Size: 40px;
  display: flex;
  justify-content: center;
}

.success-symbols-1 {
  font-size: small;
}

.success-symbols-2 {
  font-size: small;
  margin-left: 20px;
}

.success-inner-div-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #05fdd8;
}

.success-main-div {
  width: 1100px;
}

.success-btn {
  margin-right: 9%;
  width: 20%;
  margin-top: 25px;
}

.success-inner-div-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.success-Links-symbols-1 {
  margin-right: 15px;
  font-size: small;
}

.success-Links-symbols-2 {
  margin-left: 20px;
  font-size: small;
}

@media (max-width: 400px) {

  .button {
    margin-left: 10%;
    width: 20%;
  }

  .about-h1-1 {
    margin: -1% 0px 0px 0px;
    font-size: 60px;
  }
  
  .about-h1-2 {
    margin: -1.5% 0px 0px 0px;
    font-size: 60px;
  }
  
  .about-h1-3 {
    margin: -1.5% 0px -1.25% 0px;
    font-size: 60px;
  }

  .about-div {
    margin: -1% 0px 0px 18%;
  }

  .header-div {
    margin-left: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    width: 1100px;
  }
  
  .header-h1 {
    font-size: 45px;
    color: #05fdd8;
  }
  
  .header-p {
    font-size: 23px;
    color: #8d8d8d;
    margin-top: 45px;
    margin-right: 150px;
  }

  .aboutMe-div-1 {
    margin-left: 18%;
    width: 1080px;
  }

  .contactMe-h2 {
    font-size: larger;
  }

  .contactForm-div {
    margin-left: 59%;
  }

  .contactForm-input-name {
    width: 40%;
    margin-left: 40px;
  }
  
  .contactForm-input-email {
    margin-left: 43.5px;
    width: 40%;
  }
  
  .contactForm-input-subject {
    margin-left: 25px;
    width: 40%;
  }
  
  .contactForm-input-message {
    margin-left: 15px;
    width: 40%;
  }

  .btn {
    border: 1px solid #05fdd8;
    width: 30%;
    font-size: large;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    transition: 0.3s;
    color: #05fdd8;
  }

  .contactForm-submit {
    width: 250px;
    height: 60px;
    display: "flex";
    justify-content: "center";
    align-items: "center";
    background-color: #1d1d1d;
    font-family: "Orbitron";
    color: #05fdd8;
    font-size: large;
    border: none;
  }

  .linkedin-github-img {
    width: 40px;
  }
  
  .email-img {
    width: 28px;
  }
  
  .resume-img {
    width: 28px;
  }

  .link-div {
    margin-right: 100px;
  }

  .success-main-div {
    width: 400px;
  }

  .success-inner-div-1 {
    font-size: 10px;
    margin-left: 6%;
  }

  .success-inner-div-2 {
    margin-left: 9%;
  }

  .success-btn {
    margin-top: 25px;
  }

  .btn-size {
    width: 25%;
  }

  .skills p{
    font-size: xx-large;
  }

  .aboutMe-p {
    font-size: xx-large;
  }

  .projects-text-p {
    font-size: large;
  }

  .btn {
    font-size: large;
  }

}